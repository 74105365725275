import { Injectable } from '@angular/core';
import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
} from '@microsoft/signalr';
import { LocalStorageService } from './local-storage.service';
import { environment } from '../../../environments/environment';
import { Observable, of, Subject } from 'rxjs';
import {
  Chat,
  ChatWithLastMsg,
  CompactUserInfo,
  Message,
} from '../../models/chat.model';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private readonly chatNotification$ = new Subject<number>();
  private connection: HubConnection;
  private readonly apiUrl = environment.apiUrl + '/chat';

  constructor(
    private storageService: LocalStorageService,
    private http: HttpClient
  ) {}

  public setupSignalRConnection() {
    const accessToken = this.storageService.getAccessToken();
    if (!accessToken || !!this.connection) {
      return;
    }
    this.connection = new HubConnectionBuilder()
      .withUrl(environment.chatUrl, {
        transport: HttpTransportType.LongPolling,
        accessTokenFactory: () => accessToken,
      })
      .build();
    this.connection.start().then(
      () => console.log('SignalR Connected'),
      () => console.error('SignalR Error')
    );
    this.connection.on('MsgReceived', (chatId) =>
      this.chatNotification$.next(Number.parseInt(chatId, 10))
    );
  }

  public refreshConnectionIfClosed() {
    if (!!this.connection) {
      return;
    }
    this.setupSignalRConnection();
  }

  public getChatNotificationObservable(): Observable<number> {
    return this.chatNotification$.asObservable();
  }

  public notifyUsers(chatID: number) {
    if (!this.connection) {
      console.log('SignalR Connection is closed');
      this.setupSignalRConnection();
      return;
    }
    this.connection.invoke('SendChatMessage', chatID);
  }

  public saveMsg(message: Message) {
    return this.http.post<number>(this.apiUrl + '/add-msg', message);
  }

  public add(chatToAdd: Chat): Observable<number> {
    return this.http.post<number>(this.apiUrl, chatToAdd);
  }

  public getAllChatsWithLastMsg(): Observable<ChatWithLastMsg[]> {
    return this.http.get<ChatWithLastMsg[]>(this.apiUrl + '/all-with-last-msg');
  }

  public getLastMessagesForChat(chatId: number): Observable<Message[]> {
    return this.http.get<Message[]>(this.apiUrl + `/last-messages/${chatId}`);
  }

  public getNewMessagesForChat(
    chatId: number,
    lastMsgDate: Date
  ): Observable<Message[]> {
    if (!chatId || !lastMsgDate) {
      return of([]);
    }
    const date = new Date(lastMsgDate).toISOString();
    return this.http.get<Message[]>(
      this.apiUrl + `/new-messages/${chatId}/${date}`
    );
  }

  public getPreviousMessagesForChat(
    chatId: number,
    lastMsgDate: Date
  ): Observable<Message[]> {
    if (!chatId || !lastMsgDate) {
      return of([]);
    }
    const date = new Date(lastMsgDate).toISOString();
    return this.http.get<Message[]>(
      this.apiUrl + `/previous-messages/${chatId}/${date}`
    );
  }

  public getUsers(userIds: number[]): Observable<Map<number, CompactUserInfo>> {
    if (!userIds || userIds.length === 0) {
      return of(new Map<number, CompactUserInfo>());
    }
    return this.http
      .post<CompactUserInfo[]>(this.apiUrl + '/chat-users', userIds)
      .pipe(
        map((compactUserList) => {
          const resultMap = new Map<number, CompactUserInfo>();
          compactUserList.forEach((compactUser) =>
            resultMap.set(compactUser.id, compactUser)
          );
          return resultMap;
        })
      );
  }
}
